/*
    Generalities
*/

body {
	background: #fff;
	color: $Mine-shaft;
	font-family: $f-roboto-regular;
	font-size: 14px;
	margin: 0;
	padding: 0;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	overflow-x: hidden;
}

body.of-hidden {
	overflow: hidden;
}

.site-main {
	background-color: #fcf7df;
	padding-bottom: 75px;
	overflow: hidden;
	position: relative;

	.right-triangles {
		position: absolute;
		right: 0;
		margin-top: 200px;
		@include media-breakpoint-down(md){
			display: none;
		}
	}

	.forme-bg {
		position: absolute;
		right: 0;
		margin-top: -204px;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.container {
		position: relative;
		h1{
			font-family: $f-sentinel-medium;
			font-size: 25px;
			font-weight: normal;

			@media (max-width: 768px){
				font-size: 22px !important;
			}

		}
	}
}

h1,h2 {
	font-family: $f-roboto-bold;
}

h3 {
    font-family: $f-sentinel-light;
}

.legal {
	h2 {
		margin-top: 2.5rem;
	}
	h3 {
		font-family: $f-roboto-regular;
		margin-top: 2rem;
	}
	h1,h2 {
		font-family: $f-roboto-bold;
	}
}

a {
	color: #1f5a8f;
}
.sub-title {

}

figure {
	margin: 0;
	padding: 0;
}

#next-svg-icon,#prev-svg-icon {
	fill: transparent;
	height: 24px;
	stroke: #ad172b;
}

#prev-svg-icon {
	margin-right: 20px;
	transform: rotate( -180deg );
}

#next-svg-icon {
	margin-left: 20px;
}

.collapse-btn {
	border: none;
	background-color: transparent;
	padding: 0;
	font-weight: bold;
}


.collapse-btn{
	&:after {
		content: '\002B';
		font-weight: bold;
		float: right;
		margin-left: 5px;
	}
	&[aria-expanded="true"] {
		&:after {
			content: "\2212";
		}
	}
}