//Fonts
$f-fallback-heading: 'serif';
$f-fallback-content: 'sans-serif';

$f-domine-bold: 'Domine-Bold', $f-fallback-heading;

$f-sentinel-light: 'Sentinel-Light', $f-fallback-content;
$f-sentinel-medium: 'Sentinel-Medium', $f-fallback-content;
$f-sentinel-bold: 'Sentinel-Bold', $f-fallback-content;
$f-sentinel-black: 'Sentinel-Black', $f-fallback-heading;

$f-roboto-light: 'robotolight', $f-fallback-content;
$f-roboto-regular: 'roboto', $f-fallback-content;
$f-roboto-medium: 'robotomedium', $f-fallback-content;
$f-roboto-bold: 'robotobold', $f-fallback-content;
$f-roboto-black: 'robotoblack', $f-fallback-content;

$font-franklin-gothic-medium-regular: 'Franklin-Gothic-Medium-Regular', $f-fallback-content;
$font-franklin-gothic-book-normal: 'Franklin-Gothic-book-normal', $f-fallback-content;
$font-franklin-gothic-demi-normal: 'Franklin-Gothic-demi-normal', $f-fallback-content;


$font-gotham-round-bold: 'GothamRounded-Bold', $f-fallback-content;
$font-gotham-round-book: 'GothamRounded-Book', $f-fallback-content;

//Colors

$Trinidad : #EA560C;
$Monza: #E20614;
$Endeavour: #0055a5;
$Allports: #00739d;
$Mine-shaft: #3A3A3A;