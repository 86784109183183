/*
    Fonts
*/

@import "mixins";

$fonts-path: '/dist/fonts';

@include font-face(Sentinel-Light, #{$fonts-path}/Sentinel-Light, null, null, otf);
@include font-face(Sentinel-Medium, #{$fonts-path}/Sentinel-Medium, null, null, otf);
@include font-face(Sentinel-Bold, #{$fonts-path}/Sentinel-Bold, null, null, otf);
@include font-face(Sentinel-Black, #{$fonts-path}/Sentinel-Black, null, null, otf);

@include font-face(Domine-Bold, #{$fonts-path}/Domine-Bold, null, null, ttf);

@include font-face(robotolight, #{$fonts-path}/Roboto-Light, null, null, ttf);
@include font-face(roboto, #{$fonts-path}/roboto-regular-webfont, null, null, woff woff2);
@include font-face(robotomedium, #{$fonts-path}/roboto-medium-webfont, null, null, woff woff2);
@include font-face(robotobold, #{$fonts-path}/roboto-bold-webfont, null, null, woff woff2);
@include font-face(robotoblack, #{$fonts-path}/roboto-black-webfont, null, null, woff woff2);

@include font-face(Franklin-Gothic-Medium-Regular, #{$fonts-path}/Franklin-Gothic-Medium-Regular, null, null, ttf);
@include font-face(Franklin-Gothic-book-normal, #{$fonts-path}/Franklin-Gothic-book-normal, null, null, woff woff2);
@include font-face(Franklin-Gothic-demi-normal, #{$fonts-path}/Franklin-Gothic-demi-normal, null, null, woff woff2);


@include font-face(GothamRounded-Bold, #{$fonts-path}/GothamRounded-Bold, null, null, otf);
@include font-face(GothamRounded-Book, #{$fonts-path}/GothamRounded-Book, null, null, otf);

@include font-face(HCo Knockout 92, #{$fonts-path}/Knockout-92_Web, null, null, woff woff2);