// Redefine Bootstrap variables here
$primary: #0072BC;
$secondary: #55B546;
$charcoal: #333333;
$gray-text: #646464;

$btn-border-radius-lg: 7rem;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge( (
	0: 0,
	1: ($spacer * 0.25),
	2: ($spacer * 0.5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 5),
	7: ($spacer * 7),
	8: ($spacer * 8),
	9: ($spacer * 9),
	10: ($spacer * 10)
), $spacers );

$custom-file-text: (
	en: 'Browse',
	fr: 'Parcourir'
);

$font-family-sans-serif: $f-roboto-regular;
$font-family-base: $font-family-sans-serif;